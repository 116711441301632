import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

import { AppRoutes } from './shared/enums/app-routes.enum';

// Skip SSO when running in Cypress test environment
const xpoAuthenticationGuard = !!window['Cypress'] ? [] : [XpoAuthenticationGuard];

const routerDefinitions: Routes = [
  {
    path: '',
    redirectTo: `/${AppRoutes.DEFAULT_PAGE}`,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.DOOR_PLAN_PAGE,
    loadChildren: () => import('./door-plan-list/door-plan-list.module').then((m) => m.DoorPlanListModule),
    canActivate: [...xpoAuthenticationGuard],
  },
  {
    path: AppRoutes.LOAD_LANE_SUMMARY,
    loadChildren: () => import('./load-lane-summary/load-lane-summary.module').then((m) => m.LoadLaneSummaryModule),
    canActivate: [...xpoAuthenticationGuard],
  },
  {
    path: AppRoutes.EQUIPMENT_PAGE,
    loadChildren: () => import('./equipment/equipment.module').then((m) => m.EquipmentModule),
    canActivate: [...xpoAuthenticationGuard],
  },
  {
    path: AppRoutes.DIVERSION_LANE,
    loadChildren: () => import('./diversion-lane/diversion-lane.module').then((m) => m.DiversionLaneModule),
    canActivate: [...xpoAuthenticationGuard],
  },
  {
    path: AppRoutes.CROSS_DOCK_TRAILERS,
    loadChildren: () =>
      import('./cross-dock-trailers/cross-dock-trailers.module').then((m) => m.CrossDockTrailersModule),
    canActivate: [...xpoAuthenticationGuard],
  },
  {
    path: AppRoutes.SLC_PAGE,
    loadChildren: () => import('./slc/slc.module').then((m) => m.SLCModule),
    canActivate: [...xpoAuthenticationGuard],
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    redirectTo: `/${AppRoutes.DEFAULT_PAGE}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
